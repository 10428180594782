import React from "react";
import Seo from "../../components/shared/seo";
import Hero from "../../components/termsPage/hero";
import Layout from "../../components/shared/layout";
import { useIntl } from "gatsby-plugin-react-intl";

function Terms() {
  const intl = useIntl();

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({
          id: "TermsPageTitle",
        })}
        description={intl.formatMessage({
          id: "TermsPageDescription",
        })}
      />
      <Hero />
    </Layout>
  );
}

export default Terms;
